<template>
  <section class="suggested-company">
    <div class="question">
      <h4 class="fs-la mb-1 fw-bold" style="color: #b2e8fa">
        Why can't I see my company?<span class="ms-2">🧐</span>
      </h4>
      <p class="fs-sm">
        There are a small number of bussines we don't work with just yet. However, our Sloths are
        working hard to add every company to the service, and we're so grateful for you patience and
        support as we work hard on your behalf.
      </p>
    </div>

    <div class="question">
      <h4 class="fs-la mb-1 fw-bold" style="color: #b2b5fa">
        How do I update them?<span class="ms-2">📍</span>
      </h4>
      <p class="fs-sm">
        Please let us know any companies you couldn't update and our team of Sloths will point you
        in the right direction.
      </p>
    </div>

    <h5 class="fs-la">Which company shall we add next?</h5>
    <p class="fs-sm">This help us improve the service for you and others</p>

    <div class="mb-3">
      <input
        type="text"
        placeholder="e.g. Aviva"
        class="w-100 p-3 fs-md mb-2"
        :value="value"
        @input="(e) => $emit('inputValue', e.target.value)"
      /> <!-- @input="(e) => inputChange('suggestedCompanies', [e.target.value])" -->
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Vue from "vue";

export default {
  name: "SuggestedCompany",
  components: {},
  props: ["value"],
  data() {
    return {};
  },
  methods: {
    // inputChange(itemName, value) {
    //   this.$store.commit("formStore/setField", [`${itemName}`, value]);
    // },
  },
  computed: {
    ...mapState("formStore", ["suggestedCompanies"]),
  },
  watch: {},
};
</script>

<style lang="scss">
.suggested-company {
}
</style>
